<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="el-main a-fs-14 deviceTypeEdit">
      <el-form ref="form" :rules="rules" :model="form" label-position="left">
        <el-form-item label="" prop="name">
          <le-input
            v-model="form.name"
            class="editDevType"
            notnull
            label="设备类型"
            :maxlength="30"
            placeholder="请输入"
          ></le-input>
        </el-form-item>
        <el-form-item label="" prop="factory">
          <le-input
            v-model="form.factory"
            class="editDevType"
            notnull
            label="设备厂商"
            :maxlength="30"
            placeholder="请输入"
          ></le-input>
        </el-form-item>
        <el-form-item label="" prop="contactMobile">
          <le-input
            v-model="form.contactMobile"
            class="editDevType"
            notnull
            label="厂商联系电话"
            :maxlength="11"
            placeholder="请输入"
          ></le-input>
        </el-form-item>
        <el-form-item label="" prop="contactUserName">
          <le-input
            v-model="form.contactUserName"
            class="editDevType"
            notnull
            label="厂商联系人"
            :maxlength="10"
            placeholder="请输入"
          ></le-input>
        </el-form-item>
        <el-form-item prop="pic">
          <div class="uplaodImg">
            <le-upload-file-img
              ref="pic"
              v-model="form.pic"
              notnull
              label="设备图片"
              :limit="1"
            ></le-upload-file-img>
          </div>
        </el-form-item>
        <el-form-item label="" prop="price">
          <le-input
            v-model="form.price"
            class="editDevType"
            notnull
            label="设备价格(元)"
            :maxlength="10"
            placeholder="请输入"
            @input="form.price = checkMoney(form.price)"
          ></le-input>
        </el-form-item>
        <el-form-item label="" prop="socket">
          <le-input
            v-model="form.socket"
            class="editDevType"
            notnull
            label="库存"
            :maxlength="8"
            placeholder="请输入"
            @input="form.socket = form.socket.replace(/^\.+|[^\d]+/g, '')"
          ></le-input>
        </el-form-item>
        <div style="height: 100px"></div>
        <div class="a-line-t-e0 footerBox">
          <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">确定</el-button>
          <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancel">
            取消
          </el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "DeviceTypeEdit",

  data() {
    const checkImg = (rule, value, callback) => {
      const index = value.lastIndexOf(".");
      const fileType = value.substr(index + 1); // 获取文件后缀
      if (["jpg", "jpeg", "png"].indexOf(fileType) === -1) {
        callback(new Error("上传文件只能是 .jpg、.png 格式!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        id: "", //类型id
        name: "", //类型名称
        factory: "",
        contactMobile: "",
        contactUserName: "",
        pic: "",
        price: "",
        socket: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入设备类型名称", trigger: "blur" },
          {
            require: true,
            max: 50,
            message: "设备类型名称不得超过50个字符",
            trigger: "blur",
          },
        ],
        factory: [
          { required: true, message: "请输入设备厂商", trigger: "blur" },
          {
            require: true,
            max: 50,
            message: "设备厂商名称不得超过50个字符",
            trigger: "blur",
          },
        ],
        contactMobile: [
          { required: true, message: "请输入厂商联系电话", trigger: "blur" },
          {
            require: true,
            max: 15,
            message: "厂商联系电话不得超过15个字符",
            trigger: "blur",
          },
        ],
        contactUserName: [
          { required: true, message: "请输入厂商联系人", trigger: "blur" },
          {
            require: true,
            max: 10,
            message: "厂商联系人名称不得超过10个字符",
            trigger: "blur",
          },
        ],
        pic: [
          { required: true, message: "请上传设备图片", trigger: "change" },
          { validator: checkImg, trigger: "change" },
        ],
        price: [
          { required: true, message: "请输入设备价格", trigger: "blur" },
          {
            require: true,
            max: 10,
            message: "设备价格不得超过10个字符",
            trigger: "blur",
          },
        ],
        socket: [
          { required: true, message: "请输入库存", trigger: "blur" },
          {
            require: true,
            max: 8,
            message: "库存不得超过8个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.form.id = this.$route.query.id;
    if (this.form.id) this.getDeviceTypeDetails();
  },
  methods: {
    // 获取设备类型详情
    getDeviceTypeDetails() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getDeviceTypeDetail,
          method: "get",
          params: {
            deviceTypeId: this.form.id,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.form = res.result.data;
            this.form.price = (this.form.price / 100).toFixed(2);
            this.form.socket = this.form.socket ? JSON.stringify(this.form.socket) : 0;
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const reqData = JSON.parse(JSON.stringify(this.form));
          reqData.price = Number((reqData.price * 100).toFixed(0)); // 处理价格
          reqData.socket = Number(reqData.socket); // 处理库存字段类型
          this.$Axios
            ._post({
              url: this.$Config.apiUrl.editDeviceType,
              method: "post",
              params: reqData,
            })
            .then((res) => {
              if (res.result.code == 0) {
                this.$message.success("操作成功");
                this.$router.push("/device/device-type-list");
                this.$store.dispatch("delView", this.$route);
              } else {
                this.$message.error(res.result.message);
              }
            });
        }
      });
    },
    cancel() {
      this.$router.push("/device/device-type-list");
      this.$store.dispatch("delView", this.$route);
    },
    checkMoney(value) {
      value = value.replace(/[^\d.]/g, "");
      //必须保证第一个为数字而不是.
      value = value.replace(/^\./g, "");
      //保证只有出现一个.而没有多个.
      value = value.replace(/\.{2,}/g, ".");
      //保证.只出现一次，而不能出现两次以上
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      //只能输入两位小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .s-search-label {
  width: 100px !important;
  justify-content: flex-start !important;
}
.uplaodImg {
  /deep/ .s-search-label {
    width: 120px !important;
  }
}
/deep/ .uploadContent {
  margin-left: 20px !important;
}
.deviceTypeEdit {
  padding-bottom: 100px;
}
.editDevType {
  padding: 0 0 6px 0 !important;
}
.footerBox {
  // left: 255px;
  // right: 43px;
  // position: fixed;
  // bottom: 0;
  // background: #fff;
  // margin-bottom: 40px;

  left: 255px;
  right: 43px;
  position: fixed;
  bottom: 0;
  background: #fff;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
</style>
